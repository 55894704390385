import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import styled from 'styled-components'

import { space } from '../utils/tokens'
import ReactTypeformEmbed from 'react-typeform-embed/lib/ReactTypeformEmbed'


const StyledButton = styled.button`
  /* Rectangle 4: */
  border-radius: calc(15px*2);
  box-sizing: border-box;
  border: 3px solid;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0 ${space[4]}px;
  min-height: 48px;
  min-width: 6em;
  background-size: calc(200% + 4px) 100%;
  background-color: transparent;
  background-image: ${props => 
    `linear-gradient(to right, ${props.theme.colors.primaryBrand} 50%, transparent 50%)`
  };
  transition: all 0.33s;

  border-color: ${props => props.theme.colors.primaryBrand};
  color: ${props =>props.theme.colors.white};
  width: ${props => props.full ? `100%` : `auto` };

  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;


  :active,:focus {
    outline: 0;
  }

  :hover {
    background-position: calc(100% + 2px) 0;
    color: ${props => props.theme.colors.primaryBrand};
    appearance: none;
  }
  
  ${props => {
    // for styling
    if(props.transparent) {
      return (
        `border-color: ${props.theme.colors.primaryBrand};
         background: transparent;
         color: ${props.theme.colors.base};

         &:hover {
           background: ${props.theme.colors.primaryBrand};
           color: ${props.theme.colors.white};
           transition-duration: 0.5s;
         }
        `
      )
    } else if(props.white) {
      return (
        `border-color: ${props.theme.colors.white};
         background: ${props.theme.colors.white};
         color: ${props.theme.colors.base};
         box-shadow: 0 2px 13px rgba(0,0,0,0.16);
        `
      )
    } else if(props.dark) {
      return (
        `border-color: ${props.theme.colors.base};
         background: ${props.theme.colors.base};
         color: ${props.theme.colors.white};
        `
      )
    }
  }}
`;

const Button = props => (
  <StyledButton
    // allow button to be clickable if we give it a path
    onClick={props.path ? () => { navigate(props.path) } : null }
    primary={props.primary}
    transparent={props.transparent}
    white={props.white}
    size={props.size}
    className={props.className}
    style={props.style}
    full={props.full}
    {...props}
  >
    {props.text && <span>{props.text}</span>}
    {props.children}
    {props.icon}
  </StyledButton>
)

Button.propTypes = {
  text: PropTypes.string,
  primary: PropTypes.bool,
  transparent: PropTypes.bool,
  white: PropTypes.bool,
  path: PropTypes.string,
  dark: PropTypes.bool,
}

Button.defaultProps = {
  primary: true,
  transparent: false,
  white: false,
  path: null,
}

export default Button


export const CallButton = props => (
  <a href="tel:+1-929-213-9604" style={{ display: `block`, textDecoration: `none`, width: `100%` }}>
    <Button
      style={props.style}
      {...props}
    />
  </a>
)

CallButton.defaultProps = {
  text: "CALL US: 1 (929) 213-9604"
}

export const EmailButton = props => (
  <a href="mailto:contact@prtwd.com" style={{ display: `block`, textDecoration: `none`, width: `100%` }}>
    <Button text="EMAIL US" {...props} />
  </a>
)

export class QuoteButton extends Component {
  constructor(props) {
    super(props);
    this.openForm = this
      .openForm
      .bind(this);
  }

  openForm() {
    this
      .typeformEmbed
      .typeform
      .open();
  }

  render() {
    const { className, children, ...rest } = this.props

    return (
      <div
        style={{
          position: `relative`,
        }}
      >
        <Button
          text={children || 'GET A QUOTE'}
          full
          onClick={this.openForm}
          className={className}
          {...rest}
        />
        <ReactTypeformEmbed
          popup
          autoOpen={false}
          url={this.props.url}
          hideHeaders
          hideFooter
          style={{
            display: `none`,
          }}
          ref={tf => (this.typeformEmbed = tf)}
        />
      </div>
    )
  }
}
